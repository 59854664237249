// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-catering-konferens-tsx": () => import("./../../../src/templates/catering-konferens.tsx" /* webpackChunkName: "component---src-templates-catering-konferens-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-kontakt-tsx": () => import("./../../../src/templates/kontakt.tsx" /* webpackChunkName: "component---src-templates-kontakt-tsx" */),
  "component---src-templates-om-yalla-rinkeby-tsx": () => import("./../../../src/templates/om-yalla-rinkeby.tsx" /* webpackChunkName: "component---src-templates-om-yalla-rinkeby-tsx" */),
  "component---src-templates-projektet-yalla-rinkeby-2-tsx": () => import("./../../../src/templates/projektet-yalla-rinkeby-2.tsx" /* webpackChunkName: "component---src-templates-projektet-yalla-rinkeby-2-tsx" */),
  "component---src-templates-utbildningar-tsx": () => import("./../../../src/templates/utbildningar.tsx" /* webpackChunkName: "component---src-templates-utbildningar-tsx" */)
}

