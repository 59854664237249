/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "normalize.css"
import "./src/variables.css"
import "./src/fonts/hk-grotesk.css"
import "./src/global.css"
